


















































import { Component, Vue } from 'vue-property-decorator';
import { IPrescription } from '@/interfaces';
import { readPatient } from '@/store/patients/getters';
import { dispatchGetPatients } from '@/store/patients/actions';
import { readPrescriptions } from '@/store/prescriptions/getters';
import { dispatchGetPrescriptions } from '@/store/prescriptions/actions';
import { readProcedures } from '@/store/procedures/getters';
import { dispatchGetProcedures } from '@/store/procedures/actions';

@Component
export default class MedicationInfo extends Vue {
  public valid = true;
  public fullName: string = '';
  public email: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public statusIcons = ['took_.png', 'missed_.png', 'unauthorized_.png'];
  public medicationList = ['All'];

  public async mounted() {
    await dispatchGetPatients(this.$store); // TODO: remove
    await dispatchGetPrescriptions(this.$store, +this.$router.currentRoute.params.id);
    await dispatchGetProcedures(this.$store, +this.$router.currentRoute.params.id);
  }

  get patient() {
    return readPatient(this.$store)(+this.$router.currentRoute.params.id);
  }

  get prescriptions() {
    return readPrescriptions(this.$store);
  }

  get procedures() {
    return readProcedures(this.$store);
  }

  private getStatusIcons(status: number) {
    return require('@/assets/' + this.statusIcons[status]);
  }
}
